<template>
  <v-row class="ma-0" style="max-height: 500px;">
    <v-col cols="12" class="pa-0">
      <v-list v-if="workspaces.length > 0" two-line class="py-0">
        <v-list-item
          v-for="(item, i) in workspaces"
          :key="i"
          @click="checkedWorkspace(item)"
        >
          <v-list-item-avatar>
            <v-avatar
              size="38"
              :color="item.emoji ? 'transparent' : item.color"
              :class="item.emoji ? 'cr-workspace-avatar-emoji' : ''"
            >
              <span
                class="text-h7"
                :style="`color: ${invertColor(item.color)};`"
              >
                {{ item.avatar }}
              </span>
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="mb-2">
              {{ item.title }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <span>
                {{ item.ownerName }}
                <span v-show="item.followerCnt > 0">
                  {{ $t("common.60", { count: item.followerCnt }) }}
                </span>
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-icon :color="checked(item)">mdi-check-circle</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <!-- 데이터 없을 때 -->
      <v-list v-else class="py-0 d-flex align-center" height="300">
        <v-list-item>
          <v-list-item-title
            class="d-flex align-center justify-center font-weight-bold text-subtitle-2 grey--text"
          >
            {{ $t("home.14") }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
.v-avatar ::v-deep .cr-workspace-avatar-emoji {
  overflow: unset;
  span {
    font-size: 35px;
    line-height: 0;
  }
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import { invertColor } from "@/commons/utils/invertColor";

export default {
  data({ $store }) {
    let { workspaces = [] } = $store.getters["homeDialog/getParams"];
    return { workspaces };
  },
  computed: {
    ...mapGetters("home", ["getWorkflowIds"])
  },
  methods: {
    ...mapActions("home", ["addWorkflow", "deleteWorkflow"]),
    invertColor(color) {
      return invertColor(color);
    },
    checked(item) {
      const { id: workspaceId } = item;
      const index = this.getWorkflowIds.indexOf(workspaceId);

      if (index !== -1) return "primary";
      return "grey lighten-1";
    },
    checkedWorkspace(item) {
      // 삭제
      if (this.checked(item) === "primary") {
        this.deleteWorkflow(item.id);
        return;
      }
      // 추가
      this.addWorkflow(item.id);
    }
  }
};
</script>
