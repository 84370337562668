<template>
  <div class="cr-notice-widget">
    <ModuleTitle
      module="notice"
      :page="page"
      :totalPages="totalPages"
      @loadData="getList"
      @routeModule="
        $router.push({ name: 'board_list', params: { boardId: 'notice' } })
      "
    />

    <v-card class="cr-notice-content pa-3" elevation="0">
      <v-data-table
        dense
        hide-default-footer
        hide-default-header
        mobile-breakpoint="0"
        :items="notice"
        :loading="loading"
        :headers="headers"
        :items-per-page="pageSize"
        :server-items-length="totalElements"
        :item-class="() => 'item-row'"
        :class="{
          'cr-loading': loading,
          'cr-empty': !loading && !notice.length
        }"
        :no-data-text="$t('common.59')"
        @click:row="view"
      >
        <!-- 공지사항 타이틀 -->
        <template v-slot:[`item.title`]="{ item }">
          <span
            class="text-subtitle-1"
            :class="{ 'font-weight-bold': !item.seen }"
            :title="item.title"
          >
            {{ item.title }}
          </span>
        </template>
        <!-- 공지사항 작성자 -->
        <template v-slot:[`item.userName`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs" class="text-truncate">
                <span :class="{ 'font-weight-bold': !item.seen }">
                  {{ item.userName }}
                </span>
              </div>
            </template>

            <span> {{ `${item.userName} ${item.userEmail}` }} </span>
          </v-tooltip>
        </template>
        <!-- 공지사항 수정날짜 -->
        <template v-slot:[`item.updatedTimeMillis`]="{ item }">
          {{ getFullDate(item.updatedTimeMillis) }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<style lang="scss" scoped>
.cr-notice-widget ::v-deep {
  .cr-notice-content {
    border: thin solid rgba(0, 0, 0, 0.06);
    .v-data-table {
      min-height: 170px;
      max-height: 170px;
      &.cr-empty {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &.cr-loading {
        table {
          min-height: 170px;
        }
      }

      > .v-data-table__wrapper {
        table {
          .item-row {
            cursor: pointer;
            td {
              border: none;
              height: 34px;
              padding: 3px 0px;

              &.cr-notice-title {
                max-width: 1px;
              }
              &.cr-notice-name {
                width: 120px;
                max-width: 120px;
              }
              &.cr-notice-timeMillis {
                width: 140px;
                max-width: 140px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import { mapActions, mapMutations } from "vuex";
import { getFullDate } from "@/commons/utils/moment";
import { getPostList } from "@/board/api/board.api";
import ModuleTitle from "./commons/ModuleTitle.vue";
import { virtualBoardConverter } from "@/board/constant/boardType";

export default {
  components: { ModuleTitle },
  async mounted() {
    // 상세보기에서 게시판 이름표시 때문에 최초 한번 호출
    await this.loadBoards();
    this.getList(0);
  },
  data() {
    return {
      loading: true,
      notice: [],
      page: 0,
      pageSize: 5,
      totalPages: 0,
      totalElements: 0,
      headers: [
        {
          text: "제목",
          align: "start",
          cellClass: "cr-notice-title text-truncate px-2",
          sortable: false,
          value: "title"
        },
        {
          text: "작성자",
          align: "start",
          cellClass: "cr-notice-name px-2",
          sortable: false,
          value: "userName"
        },
        {
          text: "작성날짜",
          align: "start",
          cellClass: "grey--text text-caption cr-notice-timeMillis pl-0 pr-2",
          sortable: false,
          value: "updatedTimeMillis"
        }
      ]
    };
  },
  computed: {},
  methods: {
    ...mapMutations("homeDialog", ["SET_DIALOG"]),
    ...mapActions("board", ["loadBoards"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    getFullDate(timeMillis) {
      return getFullDate(timeMillis);
    },
    async getList(page) {
      this.loading = true;
      this.notice = [];
      const { data, status } = await getPostList({
        id: virtualBoardConverter("notice"),
        page,
        pageSize: this.pageSize,
        dir: "desc",
        sort: "updatedTimeMillis",
        contentSize: -1
      });

      this.loading = false;
      if (status !== 200) {
        this.openSnackbar({ message: this.$t("home.13"), type: "ERROR" });
        return;
      }

      const { content, number, totalPages, totalElements } = data;
      this.notice = content;
      this.page = number;
      this.totalPages = totalPages;
      this.totalElements = totalElements;
    },
    async view(item) {
      item.seen = true;

      this.SET_DIALOG({
        type: "noticeView",
        params: {
          postId: item.id
        }
      });
    }
  }
};
</script>
