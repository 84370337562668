<template>
  <v-card-text :class="contentsCls">
    <EventViewDialog
      v-if="dialogType === 'eventView'"
      v-bind="$props"
      v-on="$listeners"
    />
    <PostViewDialog
      v-if="dialogType === 'noticeView'"
      v-bind="$props"
      v-on="$listeners"
    />
    <MailboxSelectDialog
      v-if="dialogType === 'mailboxSelect'"
      v-bind="$props"
      v-on="$listeners"
    />
    <AddBookmarkDialog
      v-if="dialogType === 'addBookmark'"
      v-bind="$props"
      v-on="$listeners"
    />
    <SelectWorkflowDialog
      v-if="dialogType === 'selectWorkflow'"
      v-bind="$props"
      v-on="$listeners"
    />
  </v-card-text>
</template>

<script>
import { mapGetters } from "vuex";
// 게시판꺼 이용
import PostViewDialog from "@/board/views/components/dialog/PostViewDialog";
// 대시보드용
import EventViewDialog from "@/home/views/components/dialog/EventViewDialog";
import MailboxSelectDialog from "@/home/views/components/dialog/MailboxSelectDialog.vue";
import AddBookmarkDialog from "@/home/views/components/dialog/AddBookmarkDialog";
import SelectWorkflowDialog from "@/home/views/components/dialog/SelectWorkflowDialog";

export default {
  components: {
    EventViewDialog,
    PostViewDialog,
    MailboxSelectDialog,
    AddBookmarkDialog,
    SelectWorkflowDialog
  },
  props: {
    confirm: {
      default: false,
      type: Boolean,
      description: "확인 버튼"
    }
  },

  computed: {
    ...mapGetters("homeDialog", ["dialogType"]),
    contentsCls() {
      let cls = "py-0";
      if (this.dialogType === "noticeView") cls += " px-0";
      return cls;
    }
  }
};
</script>
