<template>
  <v-row class="ma-0">
    <v-col cols="12">
      <v-text-field
        v-model="title"
        autofocus
        class="mb-2"
        autocomplete="off"
        :label="$t('home.15')"
        @keyup.enter="$emit('update:confirm', true)"
      />
      <v-text-field
        ref="inputLink"
        v-model="link"
        required
        :label="$t('home.3')"
        :error-messages="errorMsg"
        @keyup.enter="$emit('update:confirm', true)"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isUrl } from "@/commons/utils/validation";

export default {
  props: {
    confirm: {
      type: Boolean,
      default: false
    }
  },
  data({ $store: { getters } }) {
    const { item } = getters["homeDialog/getParams"] || {};
    const { id = 0, title = "", link = "" } = item || {};

    return { id, title, link, errorMsg: "" };
  },
  computed: {
    ...mapGetters("homeDialog", ["getParams"])
  },
  watch: {
    async confirm(confirm) {
      if (!confirm) return;

      const { id, link, title } = this;
      if (!isUrl(link)) {
        this.$refs.inputLink.focus();
        this.errorMsg = this.$t("home.4");
        return this.$emit("update:confirm", false);
      }

      if (!link.startsWith("http://") && !link.startsWith("https://")) {
        this.link = "https://" + link;
      }

      if (!id) await this.addBookmark({ title, link: this.link });
      else await this.modifyBookmark({ id, title, link: this.link });
      this.CLOSE_DIALOG();
    },
    link() {
      this.errorMsg = "";
    }
  },

  methods: {
    ...mapMutations("homeDialog", ["CLOSE_DIALOG"]),
    ...mapActions("home", ["addBookmark", "modifyBookmark"])
  }
};
</script>
