<template>
  <v-container class="pa-0 pt-4">
    <!-- 캘린더 색상, 타이틀, 시간 정보 -->
    <v-row class="mt-2 mb-4 d-flex align-center" no-gutters>
      <v-col cols="1">
        <v-icon :color="calendarColor">mdi-checkbox-blank</v-icon>
      </v-col>
      <v-col cols="11">
        <div class="text-h6 black--text">{{ event.summary }}</div>
      </v-col>
      <v-col cols="1" />
      <v-col cols="11">
        <div>{{ event.dateString }}</div>
      </v-col>
    </v-row>

    <!-- 위치 -->
    <v-row v-if="!!event.location" no-gutters class="mb-2 d-flex align-center">
      <v-col cols="1">
        <v-icon>mdi-map-marker-outline</v-icon>
      </v-col>
      <v-col cols="11">
        <div v-text="event.location"></div>
      </v-col>
    </v-row>

    <!-- 참석자 -->
    <v-row v-if="showAttendees" no-gutters class="mb-2">
      <v-col cols="1">
        <v-icon>mdi-account-multiple-outline</v-icon>
      </v-col>
      <v-col cols="11">
        <!-- 참석자 수 -->
        <div>{{ $t("calendar.64", { value: event.attendees.length }) }}</div>
        <!-- 본인의 참석 여부 표시 -->
        <div v-if="event.isInvitation && hasWritePrivilege">
          <v-chip-group
            mandatory
            active-class="deep-purple--text text--accent-4"
            v-model="partStat"
          >
            <v-chip
              v-for="part in partStatTypes"
              :key="part.value"
              :value="part.value"
              x-small
              v-show="part.value !== 'NEEDS-ACTION'"
              @click="sendReply(part.value)"
            >
              <v-icon x-small left>{{ part.icon }}</v-icon>
              {{ $t(`calendar.${part.title}`) }}
            </v-chip>
          </v-chip-group>
        </div>
        <!-- 주최자 표시 -->
        <div class="text-no-wrap" v-if="event.isInvitation">
          <span>{{ event.organizerName }}</span>
          <span class="ml-2">{{ event.organizerEmail }}</span>
          <v-chip x-small label color="primary" class="ml-2">
            {{ $t("calendar.주최자") }}
          </v-chip>
        </div>
        <!-- 참석자들 수락/대기 표시 -->
        <div
          v-for="attendee in event.attendees"
          :key="attendee.name"
          class="text-no-wrap"
        >
          <div>
            <v-chip
              x-small
              label
              v-if="!event.isInvitation"
              :color="getColor(attendee.partStat)"
              class="mr-2 white--text"
            >
              {{ translatePartstatString(attendee.partStat) }}
            </v-chip>
            <span>{{ attendee.name }}</span>
            <span :class="attendee.name ? 'ml-2' : ''">
              {{ attendee.email }}
            </span>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- 내용 -->
    <v-row
      v-if="!!event.description"
      no-gutters
      class="mb-2 d-flex align-center"
    >
      <v-col cols="1">
        <v-icon>mdi-text</v-icon>
      </v-col>
      <v-col cols="11" class="d-inline-block text-truncate">
        <div class="description">{{ event.description }}</div>
      </v-col>
    </v-row>

    <!-- 캘린더 -->
    <v-row no-gutters class="mb-2 d-flex align-center">
      <v-col cols="1">
        <v-icon>mdi-calendar</v-icon>
      </v-col>
      <v-col cols="11">
        <div>{{ calendarTitle }}</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { CAL_CONSTANTS } from "@/calendar/constant/calConstants";
import { sendCalReplyMail } from "@/calendar/api/cal.api";

export default {
  data({ $store }) {
    let { id: userId, username } = $store.getters["auth/getUserInfo"];
    let { event = {} } = $store.getters["homeDialog/getParams"];
    let partStat = "NEEDS-ACTION";
    if (event?.attendees) {
      event.attendees.forEach(attendee => {
        if (attendee.email !== username) return;
        partStat = attendee.partStat;
      });
    }

    return {
      userId,
      event: event ?? {},
      partStat,
      partStatTypes: [
        {
          value: CAL_CONSTANTS.ATTENDEE_PARTSTAT.accept,
          title: "수락",
          icon: "mdi-check-circle-outline"
        },
        {
          value: CAL_CONSTANTS.ATTENDEE_PARTSTAT.decline,
          title: "거절",
          icon: "mdi-cancel"
        },
        {
          value: CAL_CONSTANTS.ATTENDEE_PARTSTAT.tentative,
          title: "미정",
          icon: "mdi-help-circle-outline"
        },
        {
          value: CAL_CONSTANTS.ATTENDEE_PARTSTAT.needsAction,
          title: "응답대기",
          icon: "mdi-help-circle-outline"
        }
      ]
    };
  },
  computed: {
    calendarTitle() {
      return this.event?.calendar?.title || "";
    },
    calendarColor() {
      return this.event?.calendar?.color || "";
    },
    showAttendees() {
      return this.event?.attendees?.length > 0;
    },
    hasWritePrivilege() {
      const { privilege } = this.event?.calendar ?? {};
      if (privilege < 3 || !this.isOwner) return false;
      return true;
    },
    isOwner() {
      const { owner } = this.event ?? {};
      // owner 가 없으면 본인 소유의 일정임.
      if (!owner) return true;
      if (this.userId == owner.id) return true;
      if (owner.accountType == "ORGANIZATION") return true;
      return false;
    }
  },
  methods: {
    ...mapMutations("loader", ["SET_SHOW"]),
    ...mapMutations("home", ["SET_CAL_RELOAD"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("confirm", ["confirm", "agree", "disagree"]),
    getColor(partstat) {
      switch (partstat) {
        case CAL_CONSTANTS.ATTENDEE_PARTSTAT.accept:
          return "primary";
        case CAL_CONSTANTS.ATTENDEE_PARTSTAT.tentative:
          return "cyan";
        case CAL_CONSTANTS.ATTENDEE_PARTSTAT.decline:
          return "red";
        default:
          return "";
      }
    },
    async sendReply(partStat) {
      if (this.partStat == partStat) return;
      const prevPartStat = this.partStat;
      const { iCal, organizerEmail: organizerAdr } = this.event;
      const param = { iCal, partStat, organizerAdr };

      if (partStat == "DECLINED") {
        this.sendDeclined(param, prevPartStat);
        return;
      }

      this.SET_SHOW(true);
      let message = this.$t("calendar.9");
      let type = "SUCCESS";
      const { data, status } = await sendCalReplyMail(param);
      if (status !== 200 || !data) {
        message = this.$t("calendar.10");
        type = "ERROR";
      }
      this.SET_SHOW(false);
      this.openSnackbar({ message, type });
      this.SET_CAL_RELOAD(true);
    },
    // 거절시 컨펌창
    sendDeclined(param, prevPartStat) {
      this.confirm({
        message: this.$t("calendar.67"),
        callback: async () => {
          this.SET_SHOW(true);
          this.disagree();

          let message = this.$t("calendar.7");
          let type = "SUCCESS";
          const { data, status } = await sendCalReplyMail(param);
          if (status !== 200 || !data) {
            message = this.$t("calendar.8");
            type = "ERROR";
          }

          this.SET_SHOW(false);
          this.openSnackbar({ message, type });
          this.SET_CAL_RELOAD(true);
        },
        closeCallback: () => {
          this.partStat = prevPartStat;
        }
      });
    },
    translatePartstatString(partStat) {
      return CAL_CONSTANTS.translatePartstat(partStat);
    }
  }
};
</script>
