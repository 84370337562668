<template>
  <div v-if="totalPages > 0" class="d-flex justify-end align-center">
    <v-btn icon small plain :disabled="prevBtnDisabled" @click="prevPage">
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>

    <!-- 페이징 표시 및 입력란 -->
    <v-btn
      v-if="!showPageInput"
      text
      small
      plain
      class="pa-0"
      @click="showPageInput = true"
    >
      <span>{{ `${page + 1} / ${totalPages}` }}</span>
    </v-btn>
    <v-text-field
      v-else
      dense
      hide-details
      ref="input"
      type="number"
      label="page"
      class="text-caption cr-page-input"
      @blur="pageInputBlur"
      @keydown.enter="pageInputBlur"
      @keydown.esc="showPageInput = false"
    />

    <v-btn icon small plain :disabled="nextBtnDisabled" @click="nextPage">
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
  </div>
</template>

<style lang="scss" scoped>
.cr-page-input {
  max-width: 50px;
  margin-top: -3px !important;
}
</style>

<script>
export default {
  props: {
    page: {
      type: Number,
      default: 0
    },
    totalPages: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      showPageInput: false
    };
  },
  computed: {
    prevBtnDisabled() {
      return this.page === 0;
    },
    nextBtnDisabled() {
      return this.page + 1 === this.totalPages;
    }
  },
  watch: {
    showPageInput(show) {
      if (!show) return;
      setTimeout(() => {
        this.$refs.input.focus();
      }, 0);
    }
  },
  methods: {
    prevPage() {
      let page = this.page - 1;
      if (page < 0) page = 0;

      this.$emit("loadData", page);
    },
    nextPage() {
      let page = this.page + 1;
      if (page > this.totalPages - 1) page = this.totalPages - 1;

      this.$emit("loadData", page);
    },
    pageInputBlur({ target }) {
      if (target.value !== 0 && !target.value) {
        this.showPageInput = false;
        return;
      }

      let page = target.value - 1;
      if (page < 0) page = 0;
      if (page > this.totalPages - 1) page = this.totalPages - 1;

      this.showPageInput = false;
      this.$emit("loadData", page);
    }
  }
};
</script>
