<template>
  <div style="position: relative;">
    <v-date-picker
      full-width
      no-title
      reactive
      show-adjacent-months
      color="primary"
      class="mini-calendar"
      year-icon="mdi-calendar-blank"
      v-model="_picker"
      :day-format="date => moment(date).date()"
      :picker-date.sync="pickedDate"
      :events="pickerEvents"
    />
    <v-btn icon class="cr-refresh-btn" @click="refresh">
      <v-icon color="grey lighten-1">mdi-refresh</v-icon>
    </v-btn>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getCalendars, getEvents } from "@/calendar/api/cal.api";
import { wrapSirTeamEvents } from "@/calendar/utils/EventUtils";

export default {
  props: {
    picker: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: true
    },
    pickerEventsDetail: {
      type: Array,
      default: () => []
    }
  },
  async mounted() {
    // 초기 데이터 호출
    this.initCalendar(new Date().getTime());
  },
  data() {
    return {
      calendars: [], // 개인 캘린더 목록
      pickedDate: null, // 년-월
      pickerEvents: [] // 이벤트가 있는 날짜 목록
    };
  },
  computed: {
    ...mapGetters("home", ["calReload"]),
    _picker: {
      get() {
        return this.picker;
      },
      set(picker) {
        this.$emit("update:picker", picker);
      }
    }
  },
  watch: {
    pickedDate(date, prev) {
      if (!prev) return;
      this.$emit("update:loading", true);

      const tiemMillis = new Date(date).getTime();
      this.$emit("update:picker", this.getFormatDate(tiemMillis));
      this.initCalendar(tiemMillis);
    },
    calReload(reload) {
      if (!reload) return;
      this.SET_CAL_RELOAD(false);
      this.CLOSE_DIALOG();
      this.$emit("update:loading", true);
      this.initCalendar(new Date(this._picker).getTime());
    }
  },
  methods: {
    ...mapMutations("home", ["SET_CAL_RELOAD"]),
    ...mapMutations("homeDialog", ["CLOSE_DIALOG"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    moment(date) {
      return moment(date);
    },
    getFormatDate(tiemMillis) {
      return moment.unix(tiemMillis / 1000).format("YYYY-MM-DD");
    },
    refresh() {
      this.$emit("update:loading", true);
      this.initCalendar(new Date(this.picker).getTime());
    },
    /**
     * getCals호출안하면 이벤트 업데이트안됨.
     */
    async initCalendar(tiemMillis) {
      // 초기 데이터 호출
      const { status, data = [] } = await getCalendars();
      // 캘답서버 호출 실패
      if (status !== 200) {
        this.openSnackbar({ message: this.$t("calendar.1"), type: "ERROR" });
        this.$emit("update:loading", false);
        return;
      }

      this.calendars = data
        .filter(cal => !cal.isSubscribed && cal.organId == "0")
        .map(cal => ({ ...cal }));

      const formatDate = moment(tiemMillis);
      const dtStart = formatDate.startOf("month").valueOf();
      const dtEnd = formatDate.endOf("month").valueOf();
      await this.getEvents(dtStart, dtEnd);
    },
    async getEvents(dtStart, dtEnd) {
      const params = { calendars: this.calendars, dtStart, dtEnd };
      const { data = [], status } = await getEvents(params);
      if (status !== 200) {
        // alert 이벤트를 불러오지 못함
        return;
      }

      /**
       * 연속되는 일정 처리
       */
      const pickerEventsDetail = [];
      const eventList = wrapSirTeamEvents(this.calendars, data, []);
      eventList.forEach(event => {
        const { start, end } = event;
        const date = new Date(start);
        const st = date.getTime();
        const et = new Date(end).getTime();
        const diffDate = Math.abs((st - et) / (1000 * 60 * 60 * 24));
        for (let i = 0; i <= diffDate; i += 1) {
          pickerEventsDetail.push({ ...event, ds: date.getTime() });
          this.pickerEvents.push(this.getFormatDate(date));
          date.setDate(date.getDate() + 1);
        }
      });

      this.$emit("update:loading", false);
      this.$emit("update:pickerEventsDetail", pickerEventsDetail);
    }
  }
};
</script>

<style lang="scss" scoped>
/* v-date-picker 스타일 수정 */
.mini-calendar::v-deep {
  .v-date-picker-header {
    .v-btn:first-child {
      position: absolute;
      top: 6px;
      right: 52px;
    }
    .v-date-picker-header__value {
      flex: 0.6;
      text-align: left;
    }
    .v-btn:last-child {
      position: absolute;
      top: 6px;
      right: 16px;
    }
  }

  .v-date-picker-table {
    .v-btn {
      border-radius: 10px;

      .v-date-picker-table__events > div.warning {
        background-color: red !important;
        width: 5px;
        height: 5px;
        margin-bottom: 2px;
        margin-right: 2px;
      }

      &.v-date-picker-table__current {
        border: 1px solid var(--v-primary-base) !important;
        background-color: white !important;
        border-radius: 10px !important;
        color: black !important;
      }
      &.v-btn--active {
        background-color: var(--v-primary-base) !important;
        border-radius: 10px !important;
        color: black !important;
        .v-btn__content {
          color: white;
        }
        &::before {
          opacity: 0;
        }
      }
    }
  }
}

.cr-refresh-btn {
  position: absolute;
  top: -44px;
  right: 0;
}
</style>
