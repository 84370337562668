<template>
  <v-dialog
    v-model="showDialog"
    scrollable
    persistent
    no-click-animation
    :width="width"
    :max-width="width"
    :retain-focus="false"
    @input="CLOSE_DIALOG"
    @click:outside="CLOSE_DIALOG"
  >
    <v-card>
      <v-card-title v-if="dialogTitle" :class="titleCls">
        <div class="text-h6 font-weight-bold" v-text="dialogTitle" />
      </v-card-title>

      <DialogContent :confirm.sync="confirm" />

      <v-card-actions
        v-if="dialogType != 'noticeView'"
        class="py-4 px-6 d-flex justify-center"
      >
        <v-spacer />
        <!-- 취소버튼 -->
        <v-btn v-if="getCancelBtn.show" text outlined @click="CLOSE_DIALOG">
          {{ getCancelBtn.text }}
        </v-btn>
        <!-- 확인버튼 -->
        <v-btn
          v-show="getConfirmBtn.show"
          outlined
          color="accent"
          @click="confirm = true"
        >
          {{ getConfirmBtn.text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import DialogContent from "@/home/views/components/dialog";

export default {
  components: { DialogContent },
  data() {
    return {
      confirm: false
    };
  },
  watch: {
    dialogType() {
      this.confirm = false;
    }
  },
  computed: {
    ...mapGetters("homeDialog", [
      "showDialog",
      "dialogTitle",
      "dialogType",
      "getCancelBtn",
      "getConfirmBtn",
      "getParams"
    ]),
    width() {
      switch (this.dialogType) {
        case "addBookmark":
          return "450";
        case "noticeView":
          return "800";

        default:
          return "600";
      }
    },
    titleCls() {
      let cls = "py-4";
      if (this.dialogType == "eventView") cls += " pt-6";
      return cls;
    }
  },
  methods: {
    ...mapMutations("homeDialog", ["CLOSE_DIALOG"])
  }
};
</script>
