<template>
  <v-col cols="12" class="pb-3" :class="cls" :lg="cols">
    <v-hover v-slot="{ hover }">
      <div
        class="d-flex cr-bookmark elevation-0"
        :title="item.link"
        @click="openLink"
      >
        <!-- 타이틀, 설명, 링크  -->
        <div class="py-2 px-3 cr-bookmark-info">
          <div>{{ item.title }}</div>
          <div>{{ item.desc }}</div>
          <div>{{ item.link }}</div>
        </div>
        <!-- 미리보기 이미지 -->
        <div class="d-flex" style="min-width: 100px; max-width: 100px;">
          <v-img contain :src="item.img" />
        </div>
        <!-- 수정버튼 -->
        <ModifyBtn v-show="hover" :item="item" />
        <!-- 삭제버튼 -->
        <DeleteBtn v-show="hover" @delete="deleteBookmark(item.id)" />
      </div>
    </v-hover>
  </v-col>
</template>

<style lang="scss" scoped>
.cr-bookmark {
  position: relative;
  height: 97px;
  width: 100%;
  margin-top: 1px;
  border-radius: 4px;
  background: #fff;
  border: thin solid rgba(0, 0, 0, 0.06);
  cursor: pointer;

  &:hover::after {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.08);
    content: "";
  }

  .cr-bookmark-info {
    width: calc(100% - 100px);
    > div {
      height: 30%;
      font-size: 0.85rem;
      line-height: 1.75rem;
      color: rgba(0, 0, 0, 0.6);
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      &:first-child {
        height: 40%;
        color: rgba(0, 0, 0, 0.85);
        font-size: 1rem;
        line-height: 1.75rem;
      }
    }
  }
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import DeleteBtn from "../commons/DeleteBtn.vue";
import ModifyBtn from "../commons/ModifyBtn.vue";

export default {
  components: { DeleteBtn, ModifyBtn },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("home", ["getBookmarks"]),
    ...mapGetters("auth", ["getUsedModule"]),
    fullSize() {
      const { length } = this.getBookmarks;
      return (
        !this.getUsedModule.todo ||
        this.$vuetify.breakpoint.mdAndDown ||
        (length - 1 === this.$vnode.key && length % 2 === 1)
      );
    },
    cls() {
      if (this.fullSize) return "pa-0";
      if (this.$vnode.key % 2 === 1) return "p1-3";
      return "pr-3";
    },
    cols() {
      if (this.fullSize) return 12;
      return 6;
    }
  },
  methods: {
    ...mapActions("home", ["deleteBookmark"]),
    openLink() {
      window.open(this.item.link, "_blank");
    }
  }
};
</script>
