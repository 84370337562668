<template>
  <div>
    <v-overlay :value="this.useHome" opacity="0.6" z-index="9"></v-overlay>
    <div class="tutorial-container" v-if="this.useHome">
      <div
        v-for="item in this.items"
        v-bind:key="item.class"
        class="guide-text"
        :class="
          item.class + `-guide` + ` ` + (item.active ? `active` : `false`)
        "
        :style="item.style"
      >
        <img
          :class="`${item.cursor}`"
          :src="require(`@/commons/assets/${item.cursor}.png`)"
        />
        <div class="focus" :class="`${item.cursor}`" v-if="item.focus"></div>

        {{ item.text }}
      </div>

      <!-- 하단 텍스트 상자 -->
      <div class="bottom-text-wrapper">
        <div class="text-1">
          {{ $t("tutorial.10") }}
        </div>
        <div class="text-2 mt-2">
          {{ $t("tutorial.11") }}
        </div>
        <div class="text-2">
          {{ $t("tutorial.12") }}
        </div>
        <img class="welcome" src="../../assets/tutorial_welcome.png" />
      </div>

      <!-- 닫기 -->
      <v-btn x-large rounded class="close-button" @click="close">
        {{ $t("common.닫기") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import i18n from "@/_locales";

export default {
  data() {
    return {
      items: []
    };
  },
  async mounted() {
    window.addEventListener("resize", this.resize);

    // 튜토리얼 초기화
    this.initTutorial({ module: "home" });
    if (this.useHome) {
      this.stepByStep();
    }

    // 초기화
    const info = await this.getCompanyInfo;
    let items = [];
    // 일정
    if (info.useCalendar) {
      items.push({
        class: "cr-tutorial-cal",
        text: i18n.t("tutorial.1"),
        active: false,
        align: "left",
        position: "right_top",
        cursor: "left_down",
        sibling: false,
        focus: false,
        style: {}
      });
    }

    // 공지사항
    if (info.useBoard) {
      items.push({
        // 공지사항
        class: "cr-tutorial-notice",
        text: i18n.t("tutorial.2"),
        active: false,
        align: "left",
        position: "right_top",
        cursor: "left_down",
        sibling: false,
        focus: false,
        style: {}
      });
    }

    // 메일
    items.push({
      class: "cr-tutorial-mail",
      text: i18n.t("tutorial.3"),
      active: false,
      align: "right",
      position: "left_top",
      cursor: "right_down",
      sibling: true,
      focus: false,
      style: {}
    });

    // 북마크
    items.push({
      class: "cr-tutorial-bookmark",
      text: i18n.t("tutorial.4"),
      active: false,
      align: "right",
      position: "left_top",
      cursor: "right_down",
      sibling: true,
      focus: false,
      style: {}
    });

    // 앱메뉴
    items.push({
      class: "cr-tutorial-appmenu",
      text: i18n.t("tutorial.5"),
      active: false,
      align: "right",
      position: "left_bottom",
      cursor: "right_up",
      sibling: false,
      focus: true,
      style: {}
    });

    // 워크플로우
    if (this.getCompanyInfo.useToDo) {
      items.push({
        class: "cr-tutorial-todo",
        text: i18n.t("tutorial.6"),
        active: false,
        align: "right",
        position: "left_bottom",
        cursor: "right_up",
        sibling: true,
        focus: false,
        style: {}
      });
    }

    // 기능탭
    items.push({
      class: "cr-tutorial-side-drawer",
      text: i18n.t("tutorial.7"),
      active: false,
      align: "right",
      position: "left_center",
      cursor: "right_up",
      sibling: false,
      focus: false,
      style: {}
    });

    // 메신저
    if (info.useMessenger) {
      items.push({
        class: "cr-tutorial-messenger",
        text: i18n.t("tutorial.8"),
        active: false,
        align: "right",
        position: "left_top",
        cursor: "right_down",
        sibling: false,
        focus: false,
        style: {}
      });
    }

    // 열기/닫기
    items.push({
      class: "cr-tutorial-rightmenu-close",
      text: i18n.t("tutorial.9"),
      active: false,
      align: "right",
      position: "left_top",
      cursor: "right_down",
      sibling: false,
      focus: true,
      style: {}
    });

    this.items = items;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
  watch: {
    useHome(value) {
      if (!value) return;
      this.stepByStep();
    }
  },
  computed: {
    ...mapGetters("tutorial", ["useHome", "getSpeed"]),
    ...mapGetters("auth", ["getUserInfo", "getCompanyInfo"])
  },
  methods: {
    ...mapActions("tutorial", ["initTutorial", "updateTutorialStatus"]),
    ...mapMutations("auth", ["SET_REQUEST_PASSWORD_CHANGE"]),
    getCoords: el => {
      if (!el || !el.getBoundingClientRect) {
        return {
          top: 0,
          left: 0,
          width: 0,
          height: 0
        };
      }

      const box = el.getBoundingClientRect();
      return {
        top: Math.round(box.top),
        left: Math.round(box.left),
        width: Math.round(box.width),
        height: Math.round(box.height)
      };
    },
    resize() {
      this.stepByStep();
    },
    stepByStep() {
      const list = [];
      for (var i in this.items) {
        list.push(this.items[i]);
      }

      const pid = setInterval(() => {
        const item = list.shift();
        if (!item) {
          clearInterval(pid);
          return;
        }
        item.active = false;

        const width = item.text.length * 18;
        item.style["width"] = `${width}px`;
        item.style["text-align"] = item.align;

        let el = document.querySelector(`.${item.class}`);
        if (
          item.sibling &&
          el &&
          el.parentElement &&
          el.parentElement.childNodes.length > 1
        ) {
          el = el.parentElement.childNodes[1];
        }

        // position
        const coords = this.getCoords(el);

        // top
        if (item.position.includes("top")) {
          let top = coords.top - 12;
          item.style["top"] = `${top}px`;
        }

        // bottom
        if (item.position.includes("bottom")) {
          let top = coords.top + coords.height;
          item.style["top"] = `${top}px`;
        }

        // center
        if (item.position.includes("center")) {
          item.style["top"] = "30%";
        }

        // right
        if (item.position.includes("right")) {
          let left = 44 + coords.left + coords.width;
          item.style["left"] = `${left}px`;
        }

        // left
        if (item.position.includes("left")) {
          if (item.align == "right") {
            let left = coords.left - (width + 44);
            item.style["left"] = `${left}px`;
          }
        }

        item.active = true;
      }, this.getSpeed);
    },
    async close() {
      await this.updateTutorialStatus({ module: "home", value: false });

      const { requestPasswordChange = 0 } = this.getUserInfo;
      if (requestPasswordChange) {
        this.SET_REQUEST_PASSWORD_CHANGE(1);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@keyframes flash {
  0% {
    outline-color: transparent;
  }
  100% {
    outline-color: rgba(99, 138, 255, 0.9);
  }
}

/* 튜토리얼 */
.tutorial-container {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 10;

  .guide-text {
    position: absolute;
    color: white;
    font-size: 16px;
    vertical-align: top;
    transition: 0.7s;
    opacity: 0;
    &.active {
      opacity: 1;
    }
    img {
      position: absolute;
      top: 8px;
    }
  }

  .focus {
    position: absolute;
    width: 44px;
    height: 44px;
    border: 2px solid white;
    border-radius: 50%;
    &.right_up {
      top: -46px;
      right: -90px;
    }
    &.right_down {
      top: -48px;
      right: -91px;
    }
  }

  img.left_down {
    position: absolute;
    left: -46px;
    top: 8px;
  }
  img.right_down {
    position: absolute;
    right: -46px;
    top: 8px;
  }
  img.right_up {
    position: absolute;
    right: -46px;
    top: -10px;
  }
}
/* 하단 텍스트 상자 */
.bottom-text-wrapper {
  position: fixed;
  bottom: 16px;
  left: 16px;
  color: white;
  padding: 28px 46px 22px;
  background-color: #6a8fff;
  border-radius: 30px;
  min-width: 900px;
  width: 60%;

  .text-1 {
    font-size: 22px;
    font-weight: bold;
  }

  .welcome {
    position: absolute;
    top: -46px;
    right: 33px;
  }
}
/* 닫기 버튼 */
.close-button {
  position: absolute;
  left: 60%;
  bottom: 24px;
  margin-left: 34px;
  font-size: 20px;
  font-weight: bold;
  color: rgb(99, 138, 255);
  outline-width: 8px;
  outline-style: solid;
  outline-color: transparent;
}

/* 커스텀 */
.cr-tutorial-rightmenu-close-guide {
  top: auto !important;
  left: auto !important;
  bottom: 16px;
  right: 80px;

  img {
    display: none;
  }
  .focus {
    top: -12px !important;
    right: -61px !important;
  }
}
</style>
