<template>
  <div class="d-flex justify-space-between align-center mb-2">
    <v-btn
      text
      plain
      :ripple="false"
      class="pa-0 cr-title"
      :class="(!isLink ? `cr-no-link` : ``) + ` ` + `cr-tutorial-${module}`"
      @click="routeModule"
    >
      <div class="text-h6 font-weight-bold">
        {{ title }}
      </div>
      <v-icon v-if="isLink">mdi-chevron-right</v-icon>
    </v-btn>

    <div class="d-flex align-center mt-2">
      <v-btn v-if="showAddBtn" small plain icon @click="$emit('clickPlusBtn')">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn v-if="showSetting" small plain icon @click="$emit('openSetting')">
        <v-icon>mdi-cog</v-icon>
      </v-btn>
      <PagingHandle
        v-if="showPagingHandle"
        v-on="$listeners"
        :page="page"
        :totalPages="totalPages"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-btn.cr-title.v-btn--plain::v-deep {
  .v-btn__content {
    opacity: 1 !important;
  }

  &.cr-no-link {
    cursor: default;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import PagingHandle from "./PagingHandle.vue";

export default {
  components: { PagingHandle },
  props: {
    module: {
      type: String,
      default: "mail"
    },
    page: {
      type: Number,
      default: 0
    },
    totalPages: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters("folder", ["getFolder"]),
    ...mapGetters("home", ["getMailboxId"]),
    isLink() {
      return this.moduleName !== "bookmark";
    },
    showPagingHandle() {
      return ["notice", "mail", "approval"].includes(this.module);
    },
    showSetting() {
      return ["mail"].includes(this.module);
    },
    showAddBtn() {
      return ["bookmark", "todo"].includes(this.module);
    },
    title() {
      switch (this.module) {
        case "mail":
          return (
            this.getFolder(this.getMailboxId)?.nameFolder ||
            this.$t("common.메일함")
          );
        case "cal":
          return this.$t("common.일정");
        case "notice":
          return this.$t("common.공지사항");
        case "approval":
          return "결재할 문서";
        case "todo":
          return this.$t("common.워크플로");
        case "bookmark":
          return this.$t("home.나의_북마크");

        default:
          return "";
      }
    },
    moduleName() {
      switch (this.module) {
        case "notice":
          return "board";
        default:
          return this.module;
      }
    }
  },
  methods: {
    routeModule() {
      if (!this.isLink) return;
      this.$emit("routeModule");
    }
  }
};
</script>
