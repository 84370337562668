<template>
  <div class="cr-approval-widget">
    <ModuleTitle
      module="approval"
      :page="page"
      :totalPages="totalPages"
      @loadData="loadData"
      @routeModule="routeApprovalHome"
    />
    <v-card class="cr-approval-content pa-3" elevation="0">
      <v-data-table
        dense
        hide-default-footer
        hide-default-header
        mobile-breakpoint="0"
        :items="items"
        :loading="loading"
        :headers="headers"
        :items-per-page="size"
        :server-items-length="totalElements"
        :item-class="() => 'item-row'"
        :class="{
          'cr-loading': loading,
          'cr-empty': !loading && !items.length
        }"
        no-data-text="결재할 문서가 없습니다."
        @click:row="routeDetailPage"
      >
        <!-- 기안자 -->
        <template v-slot:[`item.draftUser`]="{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs" class="text-truncate">
                <span>
                  {{ getUserName(value) }}
                </span>
              </div>
            </template>
            <span> {{ getUserTooltipText(value) }} </span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import ModuleTitle from "./commons/ModuleTitle.vue";
import API from "@/approval/api/approval.api";
import { getDateWithTime } from "@/commons/utils/moment";

export default {
  components: { ModuleTitle },
  async mounted() {
    await this.loadData(0);
  },
  data() {
    return {
      loading: false,
      page: 0,
      size: 5,
      totalElements: 0,
      totalPages: 0,
      sort: "approvalRequestTimeMillis",
      dir: "DESC",
      documents: [],
      headers: [
        {
          text: "기안제목",
          value: "title",
          width: "100%",
          align: "start",
          cellClass: "cr-approval-title text-truncate px-2"
        },
        {
          text: "결재유형",
          value: "approvalType",
          align: "start",
          cellClass: "cr-approval-type text-truncate px-2"
        },
        // {
        //   text: "기안양식",
        //   value: "formName",
        //   align: "start",
        //   cellClass: "cr-approval-form text-truncate px-2"
        // },
        {
          text: "기안자",
          value: "draftUser",
          align: "start",
          cellClass: "cr-approval-draft-user text-truncate px-2"
        },
        {
          text: "결재요청일자",
          value: "approvalRequestTimeMillis",
          align: "start",
          cellClass:
            "cr-approval-draft-time grey--text text-caption text-truncate pl-0 pr-2"
        }
      ]
    };
  },
  computed: {
    items() {
      return this.documents.map(doc => ({
        ...doc,
        draftedTimeMillis: getDateWithTime(doc?.draftedTimeMillis),
        approvalRequestTimeMillis: getDateWithTime(
          doc?.approvalRequestTimeMillis
        ),
        approvalType: this.getApprovalLineTypeName(doc?.approvalLineType)
      }));
    }
  },
  methods: {
    routeApprovalHome() {
      this.$router.push({ name: "approval_home" });
    },
    routeDetailPage(item) {
      let boxKey;
      switch (item?.approvalLineType) {
        case "APPROVAL":
        case "HELP":
          boxKey = "ab";
          break;
        case "HELP_ORGAN":
          boxKey = "oh";
          break;
        case "RECEIVE":
          boxKey = "rb";
          break;
        case "RECEIVE_ORGAN":
          boxKey = "or";
          break;
        default:
          return;
      }
      const docId = item.id;
      this.$router.push({
        name: "approval_document_view",
        params: { boxKey, docId }
      });
    },
    async loadData(page) {
      this.loading = true;
      const { status, data } = await API.getBeforeApproveDocumentList({
        page,
        size: this.size
      });
      if (status !== 200) {
        return;
      }
      const {
        content: documents,
        totalElements,
        totalPages,
        number: resPage
      } = data;
      this.documents = documents;
      this.page = resPage;
      this.totalElements = totalElements;
      this.totalPages = totalPages;
      this.loading = false;
    },
    getApprovalLineTypeName(approvalLineType) {
      switch (approvalLineType) {
        case "APPROVAL":
          return "결재";
        case "HELP":
          return "협조";
        case "HELP_ORGAN":
          return "부서협조";
        case "RECEIVE":
          return "수신";
        case "RECEIVE_ORGAN":
          return "부서수신";
      }
    },
    getUserName(draftUser) {
      return draftUser?.name ?? "-";
    },
    getUserTooltipText(draftUser) {
      const { name, email } = draftUser;
      return `${name} <${email}>`;
    }
  },
  watch: {}
};
</script>

<style lang="scss" scoped>
.cr-approval-widget ::v-deep {
  .cr-approval-content {
    border: thin solid rgba(0, 0, 0, 0.06);
    .v-data-table {
      min-height: 170px;
      max-height: 170px;
      &.cr-empty {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &.cr-loading {
        table {
          min-height: 170px;
        }
      }

      > .v-data-table__wrapper {
        table {
          .item-row {
            cursor: pointer;
            td {
              border: none;
              height: 34px;
              padding: 3px 0px;

              &.cr-approval-title {
                font-weight: bold;
                min-width: 150px;
              }
              &.cr-approval-type {
                font-weight: bold;
                width: 80px;
                max-width: 80px;
              }
              &.cr-approval-form {
                font-weight: bold;
                width: 100px;
                max-width: 100px;
              }
              &.cr-approval-draft-user {
                font-weight: bold;
                width: 120px;
                max-width: 120px;
              }
              &.cr-approval-draft-time {
                width: 140px;
                max-width: 140px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
