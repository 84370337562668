<template>
  <div class="cr-bookmark-widget">
    <ModuleTitle module="bookmark" @clickPlusBtn="addLink" />

    <v-row no-gutters class="cr-widget-content">
      <!-- data 없을 때 -->
      <AddBtn
        v-if="!getBookmarks.length"
        :text="$t('home.1')"
        @addLink="addLink"
      />
      <!-- 북마크 목록 -->
      <Item
        v-else
        v-for="(item, index) in getBookmarks"
        :key="index"
        :item="item"
      />
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.cr-bookmark-widget ::v-deep {
  .cr-widget-content {
    background-color: transparent;
    min-height: 97px;
  }
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
import ModuleTitle from "../commons/ModuleTitle.vue";
import AddBtn from "../commons/AddBtn.vue";
import Item from "./Item.vue";

export default {
  components: { ModuleTitle, AddBtn, Item },
  computed: {
    ...mapGetters("home", ["getBookmarks"])
  },
  methods: {
    ...mapMutations("homeDialog", ["SET_DIALOG"]),
    addLink() {
      const title = this.$t("home.2");
      const type = "addBookmark";
      this.SET_DIALOG({ title, type });
    }
  }
};
</script>
