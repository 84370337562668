var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cr-mail-widget"},[_c('ModuleTitle',{attrs:{"module":"mail","page":_vm.page,"totalPages":_vm.getTotalPages},on:{"loadData":_vm.loadData,"openSetting":_vm.openSetting,"routeModule":function($event){return _vm.routeMailList({ folderId: _vm.getMailboxId, page: 1 })}}}),_c('v-card',{staticClass:"cr-mail-content pa-3",attrs:{"elevation":"0"}},[_c('v-data-table',{class:{
        'cr-loading': _vm.getLoading,
        'cr-empty': !_vm.getLoading && !_vm.getMails.length
      },attrs:{"dense":"","hide-default-footer":"","hide-default-header":"","mobile-breakpoint":"0","items":_vm.getMails,"loading":_vm.getLoading,"headers":_vm.headers,"items-per-page":_vm.pageSize,"server-items-length":_vm.getTotalElements,"item-class":function () { return 'item-row'; },"no-data-text":_vm.$t('home.5')},on:{"click:row":_vm.view},scopedSlots:_vm._u([{key:"item.subject",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"text-subtitle-1",class:{ 'font-weight-bold': !item.readStatus },attrs:{"title":item.subject}},[_vm._v(" "+_vm._s(item.subject)+" ")])]}},{key:"item.adrFrom",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate"},'div',attrs,false),on),[_c('span',{class:{ 'font-weight-bold': !item.readStatus }},[_vm._v(" "+_vm._s(_vm.from(item))+" ")])])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.fromTooltip(item))+" ")])])]}},{key:"item.receivedTimeMillis",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.receivedTimeMillis)+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }