<template>
  <div class="cr-home-wrapper">
    <Dashboard />
    <Dialog />

    <HomeTutorial />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import HomeTutorial from "@/commons/views/tutorial/HomeTutorial";
import Dashboard from "./components/Dashboard";
import Dialog from "./Dialog";

export default {
  components: { HomeTutorial, Dashboard, Dialog },
  async mounted() {
    const { home } = this.getUsedModule;
    if (!home) {
      this.$router.push({ name: "mail" });
      return;
    }

    await this.initDashboardData();
  },
  computed: {
    ...mapGetters("auth", ["getUsedModule"])
  },
  methods: {
    ...mapActions("home", ["initDashboardData"])
  }
};
</script>

<style lang="scss" scoped>
.cr-home-wrapper ::v-deep {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background: #f9f9f9;
  border-radius: 8px 8px 0px 0px;
  /*
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%) !important;
  */

  .cr-title .v-btn__content {
    > div {
      font-size: 1rem !important;
    }
    > i {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.54);
    }
  }

  .text-subtitle-1,
  .v-date-picker-header__value button {
    font-size: 0.875rem !important;
  }
}
</style>
