<template>
  <div class="mt-2">
    <div class="px-6 text-subtitle-1 font-weight-bold">
      <span>
        {{ $t("home.16") }}
      </span>
      <v-divider class="mt-2" />
    </div>

    <div
      v-if="Object.keys(itemMap).length === 0"
      class="text-subtitle-2 font-weight-bold grey--text d-flex justify-center py-4"
    >
      {{ $t("home.17") }}
    </div>

    <!-- TASK 리스트 -->
    <v-list
      dense
      class="cr-task-list"
      v-for="(items, index) in itemMap"
      :key="index"
    >
      <v-subheader>{{ index }}</v-subheader>
      <v-list-item
        two-line
        :ripple="false"
        :key="item.id"
        v-for="item in items"
        @click="() => nodeClick(item)"
      >
        <v-list-item-content class="pl-2">
          <!-- 일정 타이틀 -->
          <v-list-item-title class="font-weight-bold text-subtitle-2">
            {{ item.title }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <div
              class="d-inline-block cr-chip date mr-1"
              :style="{
                background: linearGradient(item)
              }"
            >
              {{ getDateString(item) }}
            </div>

            <div
              class="d-inline-block cr-chip mr-1"
              :style="{
                'background-color': opt.color,
                color: getTextColor(opt.color)
              }"
              v-for="opt in item.options.items"
              :key="`${opt.title}-${opt.color}`"
            >
              {{ opt.title }}
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { getTaskList } from "@/todo/api/task.api";
import moment from "moment";

export default {
  props: {
    picker: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      itemMap: []
    };
  },
  mounted() {
    this.loadList();
  },
  watch: {
    picker() {
      this.loadList();
    }
  },
  methods: {
    getTextColor(backgroundColor) {
      const c = backgroundColor.substring(1);
      const rgb = parseInt(c, 16);
      const r = (rgb >> 16) & 0xff;
      const g = (rgb >> 8) & 0xff;
      const b = (rgb >> 0) & 0xff;
      const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
      return luma < 127.5 ? "white" : "black";
    },
    linearGradient(item) {
      const today = moment(this.picker, "YYYY-MM-DD").format("YYYYMMDD");
      const { startDate, endDate } = item;
      const d1 = endDate - startDate;
      const d2 = today - startDate;
      const percent = (Math.floor(d2) / d1) * 100;
      return `linear-gradient(to right, #a7c4ff ${percent}%, #e0e0e0 ${percent}%)`;
    },
    getDateString(item) {
      if (item.startDate === item.endDate) {
        return moment(item.startDate, "YYYYMMDD").format("M. D");
      }
      return (
        moment(item.startDate, "YYYYMMDD").format("M. D") +
        " - " +
        moment(item.endDate, "YYYYMMDD").format("M. D")
      );
    },
    async loadList() {
      const selectedDate = this.picker.split("-").join("");
      const { status, data } = await getTaskList({
        startDate: selectedDate,
        endDate: selectedDate
      });

      if (status !== 200) {
        this.itemMap = [];
        return;
      }

      this.itemMap = data;
    },
    nodeClick(item) {
      const params = {
        filterId: 0,
        groupId: item.groupId,
        itemId: item.itemId,
        parentId: 0,
        viewType: "table"
      };
      window.open(
        `/#/todo/${item.workspaceId}/${item.boardId}/${JSON.stringify(params)}`,
        "",
        "width=1200,height=800,location=no,directories=no,resizable=no,status=no,toolbar=yes,menubar=no,scrollbars=yes"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-chip {
  font-size: 11px;
  padding: 3px 4px 2px;
  line-height: 1;
  background-color: #ddd;
  border-radius: 2px;

  &.date {
    font-weight: 600;
  }
}
.cr-task-list .v-subheader {
  padding: 8px 0px 0px 24px;
  height: auto;
  font-size: 12px;
}
</style>
