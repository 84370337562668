<template>
  <v-list-item :ripple="false" @click="viewEvent(item)">
    <!-- 캘린더 색상 -->
    <div class="cr-calendar-color" :style="calendarColor(item)" />
    <v-list-item-content class="pl-2">
      <!-- 일정 타이틀 -->
      <v-list-item-title class="font-weight-bold text-subtitle-2">
        {{ item.detail.summary }}
      </v-list-item-title>
    </v-list-item-content>
    <!-- 시간대 -->
    <v-list-item-action class="mt-0 mb-0">
      <v-list-item-action-text class="font-weight-bold text-caption">
        <div v-if="item.detail.isAllDay">{{ $t("home.8") }}</div>
        <div v-else>
          <div>{{ dateFormat(item.detail.dtStart) }}</div>
          <div>{{ dateFormat(item.detail.dtEnd) }}</div>
        </div>
      </v-list-item-action-text>
    </v-list-item-action>
  </v-list-item>
</template>

<style lang="scss" scoped>
.v-list-item {
  padding: 0px 24px;
  margin-bottom: 12px;
  height: 40px;
  &:last-child {
    margin-bottom: 0px;
  }
  .cr-calendar-color {
    width: 8px;
    height: 17px;
    border-radius: 2px;
    margin-bottom: 1px;
  }

  .v-list-item__action {
    width: 70px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
</style>

<script>
import moment from "moment";
import { mapMutations } from "vuex";

export default {
  props: {
    item: { type: Object, default: () => ({}) }
  },
  methods: {
    ...mapMutations("homeDialog", ["SET_DIALOG"]),
    calendarColor(item) {
      if (!item?.calendar?.color) return "";
      return `background-color: ${item.calendar.color}`;
    },
    dateFormat(date) {
      if (!date) return "";
      return moment.unix(new Date(date).getTime() / 1000).format("A h:mm");
    },
    viewEvent(event) {
      let eventDetail = { ...event.detail };
      eventDetail.calendar = event.calendar;

      // 시간 정보
      const start = moment(eventDetail.dtStart);
      const end = moment(eventDetail.dtEnd);
      let dStr = "";
      if (eventDetail.isAllDay) {
        dStr = start.format("M.D (ddd)");
        if (start.format("M.D") !== end.format("M.D")) {
          dStr += end.format(" - M.D (ddd)");
        }
      } else {
        dStr = start.format("M.D (ddd) A hh:mm ~ ");
        if (start.format("A") !== end.format("A")) dStr += end.format("A ");
        dStr += end.format("hh:mm");
      }
      eventDetail.dateString = dStr;

      // 주최자
      if (event.isInvitation && eventDetail.organizer) {
        eventDetail.organizerName = eventDetail.organizer.name;
        eventDetail.organizerEmail = eventDetail.organizer.email;
      }
      // 다이얼로그 오픈
      this.SET_DIALOG({
        type: "eventView",
        params: { event: eventDetail },
        cancelBtn: { show: true, text: this.$t("common.닫기") },
        confirmBtn: { show: false }
      });
    }
  }
};
</script>
