var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cr-approval-widget"},[_c('ModuleTitle',{attrs:{"module":"approval","page":_vm.page,"totalPages":_vm.totalPages},on:{"loadData":_vm.loadData,"routeModule":_vm.routeApprovalHome}}),_c('v-card',{staticClass:"cr-approval-content pa-3",attrs:{"elevation":"0"}},[_c('v-data-table',{class:{
        'cr-loading': _vm.loading,
        'cr-empty': !_vm.loading && !_vm.items.length
      },attrs:{"dense":"","hide-default-footer":"","hide-default-header":"","mobile-breakpoint":"0","items":_vm.items,"loading":_vm.loading,"headers":_vm.headers,"items-per-page":_vm.size,"server-items-length":_vm.totalElements,"item-class":function () { return 'item-row'; },"no-data-text":"결재할 문서가 없습니다."},on:{"click:row":_vm.routeDetailPage},scopedSlots:_vm._u([{key:"item.draftUser",fn:function(ref){
      var value = ref.value;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate"},'div',attrs,false),on),[_c('span',[_vm._v(" "+_vm._s(_vm.getUserName(value))+" ")])])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.getUserTooltipText(value))+" ")])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }