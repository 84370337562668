<template>
  <v-row no-gutters class="pt-4 px-2">
    <!-- 첫번째 열 -->
    <v-col class="mb-10 px-4" cols="12" :lg="getUsedModule.cal ? '3' : '1'">
      <Schedule v-if="getUsedModule.cal" />
    </v-col>

    <!-- 두번째 열 -->
    <v-col class="px-sm-4" cols="12" :lg="getUsedModule.cal ? '6' : '7'">
      <v-row no-gutters>
        <!-- 공지사항 -->
        <v-col v-if="getUsedModule.board" cols="12" class="mb-10">
          <Notice />
        </v-col>
        <!-- 메일 -->
        <v-col cols="12" class="mb-10">
          <Mailbox />
        </v-col>
        <!-- 전자결재 -->
        <v-col v-if="showApprovalSection" cols="12" class="mb-10">
          <ApprovalBox />
        </v-col>
        <!-- 북마크 -->
        <v-col v-if="getUsedModule.todo" cols="12" class="mb-10">
          <Bookmark />
        </v-col>
      </v-row>
    </v-col>

    <!-- 세번째 열 -->
    <v-col class="mb-10 px-4" cols="12" lg="3">
      <Workflow v-if="getUsedModule.todo" />
      <Bookmark v-else />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import Schedule from "./widget/schedule";
import Notice from "./widget/Notice";
import Mailbox from "./widget/Mailbox";
import ApprovalBox from "./widget/ApprovalBox";
import Bookmark from "./widget/bookmark";
import Workflow from "./widget/workflow";

export default {
  components: { Schedule, Notice, Mailbox, ApprovalBox, Bookmark, Workflow },
  computed: {
    ...mapGetters("auth", ["getUsedModule", "getCompanyInfo"]),
    // FIXME: 알파테스트 서비스는 크리니티만 임시적으로 오픈.
    showApprovalSection() {
      return this.getCompanyInfo.id === 1 && this.getUsedModule.approval;
    }
  }
};
</script>
