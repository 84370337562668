<template>
  <div class="cr-mail-widget">
    <ModuleTitle
      module="mail"
      :page="page"
      :totalPages="getTotalPages"
      @loadData="loadData"
      @openSetting="openSetting"
      @routeModule="routeMailList({ folderId: getMailboxId, page: 1 })"
    />

    <v-card class="cr-mail-content pa-3" elevation="0">
      <v-data-table
        dense
        hide-default-footer
        hide-default-header
        mobile-breakpoint="0"
        :items="getMails"
        :loading="getLoading"
        :headers="headers"
        :items-per-page="pageSize"
        :server-items-length="getTotalElements"
        :item-class="() => 'item-row'"
        :class="{
          'cr-loading': getLoading,
          'cr-empty': !getLoading && !getMails.length
        }"
        :no-data-text="$t('home.5')"
        @click:row="view"
      >
        <!-- 메일 제목 -->
        <template v-slot:[`item.subject`]="{ item }">
          <span
            class="text-subtitle-1"
            :class="{ 'font-weight-bold': !item.readStatus }"
            :title="item.subject"
          >
            {{ item.subject }}
          </span>
        </template>
        <!-- 보낸 사람 -->
        <template v-slot:[`item.adrFrom`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs" class="text-truncate">
                <span :class="{ 'font-weight-bold': !item.readStatus }">
                  {{ from(item) }}
                </span>
              </div>
            </template>

            <span> {{ fromTooltip(item) }} </span>
          </v-tooltip>
        </template>
        <!-- 받은 날짜 -->
        <template v-slot:[`item.receivedTimeMillis`]="{ item }">
          {{ item.receivedTimeMillis }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<style lang="scss" scoped>
.cr-mail-widget ::v-deep {
  .cr-mail-content {
    border: thin solid rgba(0, 0, 0, 0.06);
    .v-data-table {
      min-height: 170px;
      max-height: 170px;
      &.cr-empty {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &.cr-loading {
        table {
          min-height: 170px;
        }
      }

      > .v-data-table__wrapper {
        table {
          .item-row {
            cursor: pointer;
            td {
              border: none;
              height: 34px;
              padding: 3px 0px;

              &.cr-mail-subject {
                max-width: 1px;
              }
              &.cr-mail-from {
                width: 120px;
                max-width: 120px;
              }
              &.cr-mail-timeMillis {
                width: 140px;
                max-width: 140px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ModuleTitle from "./commons/ModuleTitle.vue";

export default {
  components: { ModuleTitle },
  async mounted() {
    // 메일함 표시를 위해 최초 한번 호출
    await this.getMailConfig();
    await this.loadConfig("MAIL");
    await this.getDefaultFolders();
    // 메일함 목록 가져오고 mailboxId 확인 -> 현재 메일함목록에 존재하지 않으면 전체메일로 바꾼다.
    if (!this.getFolder(this.getMailboxId)) await this.SET_MAILBOX_ID(-1);
    // 메일목록 호출
    this.loadData(0);
  },
  data() {
    return {
      pageSize: 5,
      headers: [
        {
          text: "제목",
          align: "start",
          cellClass: "cr-mail-subject text-truncate px-2",
          sortable: false,
          value: "subject"
        },
        {
          text: "작성자",
          align: "start",
          cellClass: "cr-mail-from px-2",
          sortable: false,
          value: "adrFrom"
        },
        {
          text: "받은 날짜",
          align: "start",
          cellClass: "grey--text text-caption cr-mail-timeMillis pl-0 pr-2",
          sortable: false,
          value: "receivedTimeMillis"
        }
      ]
    };
  },
  computed: {
    ...mapGetters("home", ["getMailboxId"]),
    ...mapGetters("folder", ["getFolder"]),
    ...mapGetters("mail", [
      "getMails",
      "getPage",
      "getLoading",
      "getTotalPages",
      "getTotalElements"
    ]),
    page() {
      return this.getPage - 1;
    },
    from() {
      return mail => {
        return mail?.adrFrom?.personal || mail?.adrFrom?.address;
      };
    },
    fromTooltip() {
      return mail => {
        let personal = mail?.adrFrom?.personal;
        if (personal) personal = `"${personal}"`;

        return `${personal} <${mail?.adrFrom?.address}>`;
      };
    }
  },
  watch: {
    getMailboxId(next, prev) {
      if (prev === null) return;
      this.loadData(0);
    }
  },
  methods: {
    ...mapMutations("home", ["SET_MAILBOX_ID"]),
    ...mapMutations("homeDialog", ["SET_DIALOG"]),
    ...mapActions("serviceConfig", ["loadConfig"]),
    ...mapActions("folder", ["getDefaultFolders"]),
    ...mapActions("mail", ["getList"]),
    ...mapActions("mailConfig", ["getMailConfig"]),
    ...mapActions("mailRoute", ["routeMailList"]),
    openSetting() {
      this.SET_DIALOG({
        title: this.$t("home.6"),
        type: "mailboxSelect",
        params: {
          mailboxId: this.getMailboxId
        }
      });
    },
    view(mail) {
      const basedId = mail.isExchange ? mail.parentMailId : mail.mailId;
      const list = { dir: "desc", sort: "receivedTimeMillis", search: {} };
      const view = { basedId, selectedDataId: mail.dataId };

      window.open(
        `/#/mail/view/popup/${JSON.stringify({ list, view })}`,
        `mailview-${basedId}`,
        "width=1050,height=870,location=no,directories=no,resizable=no,status=no,toolbar=yes,menubar=no,scrollbars=yes"
      );
    },
    loadData(page) {
      const { getMailboxId: folderId, pageSize } = this;
      this.getList({ folderId, page: page + 1, pageSize });
    }
  }
};
</script>
