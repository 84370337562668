<template>
  <v-col cols="12" class="pb-3">
    <v-hover v-slot="{ hover }">
      <div
        class="d-flex cr-workspace align-center elevation-0"
        @click="routeWorkspace({ workspaceId: item.id })"
      >
        <!-- 타이틀, 설명, 링크  -->
        <div
          class="d-flex align-center px-4"
          style="min-width: 0px; height: 100%;"
        >
          <v-avatar
            size="38"
            :color="item.emoji ? 'transparent' : item.color"
            :class="item.emoji ? 'cr-workspace-avatar-emoji mr-2' : 'mr-2'"
          >
            <span class="text-h7" :style="`color: ${invertColor(item.color)};`">
              {{ item.avatar }}
            </span>
          </v-avatar>
          <div class="d-flex flex-column pr-2 cr-todo-title ml-2">
            <span class="d-inline-block text-truncate" style="height: 30px;">
              {{ item.title }}
            </span>
            <span>
              {{ item.ownerName }}
              <span v-show="item.followerCnt > 0">
                외 {{ item.followerCnt }}명
              </span>
            </span>
          </div>
        </div>

        <!-- 삭제버튼 -->
        <DeleteBtn v-show="hover" @delete="deleteWorkflow(item.id)" />
      </div>
    </v-hover>
  </v-col>
</template>

<style lang="scss" scoped>
.cr-workspace {
  position: relative;
  height: 97px;
  width: 100%;
  margin-top: 1px;
  border-radius: 4px;
  background: #fff;
  border: thin solid rgba(0, 0, 0, 0.06);
  cursor: pointer;

  &:hover::after {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.08);
    content: "";
  }

  .cr-todo-title {
    min-width: 0px;
    > span {
      font-size: 0.85rem;
      line-height: 1.75rem;
      color: rgba(0, 0, 0, 0.6);

      &:first-child {
        color: rgba(0, 0, 0, 0.85);
        font-size: 1rem;
        line-height: 1.75rem;
      }
    }
  }

  .v-avatar.cr-workspace-avatar-emoji {
    overflow: unset;
    span {
      font-size: 35px;
      line-height: 0;
    }
  }
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import { invertColor } from "@/commons/utils/invertColor";
import DeleteBtn from "../commons/DeleteBtn.vue";

export default {
  components: { DeleteBtn },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("home", ["getBookmarks"]),
    ...mapGetters("auth", ["getUsedModule"]),
    fullSize() {
      const { length } = this.getBookmarks;
      return (
        !this.getUsedModule.todo ||
        this.$vuetify.breakpoint.mdAndDown ||
        (length - 1 === this.$vnode.key && length % 2 === 1)
      );
    },
    cls() {
      if (this.fullSize) return "pa-0";
      if (this.$vnode.key % 2 === 1) return "p1-3";
      return "pr-3";
    },
    cols() {
      if (this.fullSize) return 12;
      return 6;
    }
  },
  methods: {
    ...mapActions("home", ["deleteWorkflow"]),
    ...mapActions("todoRoute", ["routeWorkspace"]),
    invertColor(color) {
      return invertColor(color);
    }
  }
};
</script>
