<template>
  <v-btn
    class="cr-modify-btn"
    tile
    icon
    small
    color="white"
    @click.stop="addLink"
  >
    <v-icon size="20">mdi-pencil-outline</v-icon>
  </v-btn>
</template>

<style lang="scss" scoped>
.cr-modify-btn {
  position: absolute;
  z-index: 3;
  top: 4px;
  right: 35px;
  border-radius: 4px;
  background-color: #9e9e9e;
}
</style>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    ...mapMutations("homeDialog", ["SET_DIALOG"]),
    addLink() {
      const title = this.$t("home.2");
      const type = "addBookmark";
      this.SET_DIALOG({ title, type, params: { item: this.item } });
    }
  }
};
</script>
