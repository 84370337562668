<template>
  <div class="cr-workflow-widget">
    <ModuleTitle
      module="todo"
      @clickPlusBtn="addWorkspace"
      @routeModule="routeTodo"
    />

    <v-row no-gutters class="cr-widget-content">
      <!-- data 없을 때 -->
      <AddBtn
        v-if="!getWorkflowIds.length"
        :text="$t('home.9')"
        :icon="'mdi-view-agenda-outline'"
        @addLink="addWorkspace"
      />
      <!-- 워크플로 워크스페이스 목록 -->
      <Item
        v-else
        v-for="(item, index) in worksapces"
        :key="index"
        :item="item"
      />
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.cr-workflow-widget ::v-deep {
  .cr-widget-content {
    background-color: transparent;
    min-height: 97px;
  }
}
</style>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex";
import { getWorkspaces } from "@/todo/api/workspace.api";
import ModuleTitle from "../commons/ModuleTitle.vue";
import AddBtn from "../commons/AddBtn.vue";
import Item from "./Item.vue";

export default {
  components: { ModuleTitle, AddBtn, Item },
  async mounted() {
    const { data, status } = await getWorkspaces();
    if (status !== 200) {
      // alert
      return;
    }

    const myWorkspaces = [];
    const { allWorkspaces: aw, myWorkspaces: mw } = data;
    mw.forEach(id => {
      const index = aw.findIndex(w => w.id === id);
      if (index === -1) return;

      const w = aw[index];
      w["avatar"] = w.emoji || w.title?.slice(0, 2) || "";
      myWorkspaces.push(w);
    });

    this.myWorkspaces = myWorkspaces;
    this.allWorkspaces = aw;
  },
  data() {
    return {
      allWorkspaces: [],
      myWorkspaces: []
    };
  },
  computed: {
    ...mapGetters("home", ["getWorkflowIds"]),
    worksapces() {
      const worksapces = [];
      this.getWorkflowIds.forEach(id => {
        const index = this.allWorkspaces.findIndex(w => w.id === id);
        if (index === -1) return;

        worksapces.push(this.allWorkspaces[index]);
      });

      return worksapces;
    }
  },
  methods: {
    ...mapMutations("homeDialog", ["SET_DIALOG"]),
    ...mapActions("todoRoute", ["routeTodo"]),
    addWorkspace() {
      this.SET_DIALOG({
        type: "selectWorkflow",
        title: this.$t("home.10"),
        params: { workspaces: this.myWorkspaces },
        cancelBtn: { show: true, text: this.$t("common.닫기") },
        confirmBtn: { show: false }
      });
    }
  }
};
</script>
