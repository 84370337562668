<template>
  <v-row class="ma-0">
    <v-col class="d-flex align-center" cols="12" sm="4">
      {{ $t("mail.메일함") }}
    </v-col>
    <v-col cols="12" sm="8">
      <MailboxSelect :folderId="folderId" @change="changeFolder" />
    </v-col>
  </v-row>
</template>

<script>
import MailboxSelect from "@/mail/views/components/main/commons/mailboxSelect";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: { MailboxSelect },
  props: {
    confirm: {
      default: false,
      type: Boolean,
      description: "확인 버튼"
    }
  },
  data({ $store }) {
    const getters = $store.getters;
    const params = getters["homeDialog/getParams"];
    return {
      folderId: params.mailboxId || -1
    };
  },
  watch: {
    async confirm(confirm) {
      if (confirm) {
        if (this.getParams.mailboxId != this.folderId) {
          await this.updateMailboxId(this.folderId);
        }
        this.CLOSE_DIALOG();
      }
    }
  },
  computed: {
    ...mapGetters("homeDialog", ["getParams"])
  },
  methods: {
    ...mapMutations("homeDialog", ["CLOSE_DIALOG"]),
    ...mapActions("home", ["updateMailboxId"]),
    changeFolder(folder) {
      this.folderId = folder.id;
    }
  }
};
</script>
