<template>
  <div class="cr-schedule-widget mb-10">
    <ModuleTitle module="cal" @routeModule="$router.push({ name: 'cal' })" />

    <v-progress-linear v-show="loading" indeterminate color="primary" />

    <v-card
      class="cr-schedule-content py-1 pt-0"
      :class="{ 'pt-1': !loading, 'cr-loading': loading }"
      elevation="0"
    >
      <div v-show="loading" class="cr-loading-guard" />
      <DatePicker
        :picker.sync="picker"
        :loading.sync="loading"
        :pickerEventsDetail.sync="pickerEventsDetail"
      />
      <EventList :picker="picker" :pickerEventsDetail="pickerEventsDetail" />
      <TaskList :picker="picker" />
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { getDateWithoutTime } from "@/commons/utils/moment";
import ModuleTitle from "../commons/ModuleTitle.vue";
import DatePicker from "./DatePicker.vue";
import EventList from "./EventList.vue";
import TaskList from "./TaskList.vue";

export default {
  components: { ModuleTitle, DatePicker, EventList, TaskList },
  data() {
    return {
      loading: true,
      picker: getDateWithoutTime(new Date().getTime(), "YYYY-MM-DD"), // v-date-picker model (년-월-일)
      pickerEventsDetail: [] // 이벤트가 있는 날짜 상세내용
    };
  },
  computed: {
    ...mapGetters("auth", ["getUserInfo"])
  }
};
</script>

<style lang="scss" scoped>
.cr-schedule-widget ::v-deep {
  .cr-schedule-content {
    border: thin solid rgba(0, 0, 0, 0.06);

    &.cr-loading {
      position: relative;
      opacity: 0.35;
    }
    .cr-loading-guard {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 1;
      background: rgba(0, 0, 0, 0.08);
    }
  }
}
</style>
