<template>
  <v-col class="pa-2 cr-no-data" cols="12">
    <v-btn text block depressed height="81" @click="$emit('addLink')">
      <v-icon size="30" class="mr-1" color="grey darken-1">
        {{ icon }}
      </v-icon>
      <span class="text-subtitle-1 grey--text text--darken-1 text-capitalize">
        {{ text }}
      </span>
    </v-btn>
  </v-col>
</template>

<style lang="scss" scoped>
.cr-no-data {
  background: #fff;
  border: thin solid rgba(0, 0, 0, 0.06);
}
</style>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "mdi-list-box-outline"
    },
    text: {
      type: String,
      default: ""
    }
  }
};
</script>
