<template>
  <v-btn
    class="cr-delete-btn"
    tile
    icon
    small
    color="white"
    @click.stop="$emit('delete')"
  >
    <v-icon size="20">mdi-trash-can-outline</v-icon>
  </v-btn>
</template>

<style lang="scss" scoped>
.cr-delete-btn {
  position: absolute;
  z-index: 3;
  top: 4px;
  right: 4px;
  border-radius: 4px;
  background-color: #9e9e9e;
}
</style>

<script>
export default {};
</script>
