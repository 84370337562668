<template>
  <div class="mt-2">
    <div class="px-6 text-subtitle-1 font-weight-bold">
      <span>{{ _picker }}</span>
      <span class="text-subtitle-2 ml-2">
        {{ $t(`common.날짜_${pickerDay}`) }}
      </span>
      <v-divider class="mt-2" />
    </div>

    <v-list dense class="cr-event-list">
      <!-- 데이터 없음 -->
      <v-list-item v-if="!eventList.length">
        <v-list-item-title
          class="d-flex align-center justify-center font-weight-bold text-subtitle-2 grey--text"
        >
          {{ $t("home.7") }}
        </v-list-item-title>
      </v-list-item>
      <!-- 이벤트 리스트 -->
      <EventListItem v-for="(item, i) in eventList" :key="i" :item="item" />
    </v-list>
  </div>
</template>

<script>
import moment from "moment";
import { getMonthAndDateLowerCase } from "@/commons/utils/moment";
import EventListItem from "./EventListItem.vue";

export default {
  components: { EventListItem },
  props: {
    picker: {
      type: String,
      default: null
    },
    pickerEventsDetail: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    _picker() {
      return getMonthAndDateLowerCase(new Date(this.picker).getTime());
    },
    pickerDay() {
      return new Date(this.picker).getDay();
    },
    eventList() {
      if (!this.picker) return [];

      const dtStart = this.getTimeMillis(this.picker);
      return (
        this.pickerEventsDetail.filter(
          c => this.getTimeMillis(c.ds) === dtStart
        ) || []
      );
    }
  },
  methods: {
    getTimeMillis(date) {
      return moment(
        moment.unix(new Date(date).getTime() / 1000).format("YYYY-MM-DD")
      ).valueOf();
    }
  }
};
</script>
