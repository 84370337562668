var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cr-notice-widget"},[_c('ModuleTitle',{attrs:{"module":"notice","page":_vm.page,"totalPages":_vm.totalPages},on:{"loadData":_vm.getList,"routeModule":function($event){return _vm.$router.push({ name: 'board_list', params: { boardId: 'notice' } })}}}),_c('v-card',{staticClass:"cr-notice-content pa-3",attrs:{"elevation":"0"}},[_c('v-data-table',{class:{
        'cr-loading': _vm.loading,
        'cr-empty': !_vm.loading && !_vm.notice.length
      },attrs:{"dense":"","hide-default-footer":"","hide-default-header":"","mobile-breakpoint":"0","items":_vm.notice,"loading":_vm.loading,"headers":_vm.headers,"items-per-page":_vm.pageSize,"server-items-length":_vm.totalElements,"item-class":function () { return 'item-row'; },"no-data-text":_vm.$t('common.59')},on:{"click:row":_vm.view},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"text-subtitle-1",class:{ 'font-weight-bold': !item.seen },attrs:{"title":item.title}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.userName",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate"},'div',attrs,false),on),[_c('span',{class:{ 'font-weight-bold': !item.seen }},[_vm._v(" "+_vm._s(item.userName)+" ")])])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(((item.userName) + " " + (item.userEmail)))+" ")])])]}},{key:"item.updatedTimeMillis",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFullDate(item.updatedTimeMillis))+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }